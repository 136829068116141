/* Sim.css */
.sim-container {
  width: 100%;
  height: 100vh; /* Full viewport height, no vertical scroll */
  position: relative;
  display: flex;
  flex-direction: column; /* Default to column */
  align-items: stretch; /* Stretch items to fill container width */
  justify-content: flex-start; /* Align items to the start in column direction */
  background-color: #3d3d3d; /* Light grey background */
  overflow: hidden; /* Prevent body scroll */
}

.sim-container.editor-mode { /* Apply when .editor-mode class is on sim-container */
  display: flex;
  flex-direction: row; /*  Crucially, set to row for side-by-side layout */
  align-items: stretch; /* Stretch editor and main content to full height */
}

/* In editor mode, make sim-container row to place editor side-by-side with canvas */
.sim-container .editor-container {
  display: flex;
  flex-direction: column; /* Editor itself is column layout */
  height: 100%; /* Editor takes full height */
  width: 100%; /* Editor takes full width initially, adjust if needed */
}

.sim-container .editor-content {
  display: flex;
  flex: 1; /* Editor content takes remaining space */
  height: 100%; /* Ensure editor content takes full height */
}

.sim-container .main-content {
  flex: 1; /* Main content in editor (canvas wrapper) takes remaining space */
  display: flex; /* Ensure canvas can take full space */
  flex-direction: column; /* For canvas inside */
  align-items: stretch;
  justify-content: flex-start;
  height: 100%; /* Take full height of main-content */
  overflow: hidden; /* Ensure no scroll from main content itself */
}


.sim-container canvas {
  flex: 1; /* Canvas takes up available space in main-content */
  border: 1px solid #ccc;
  background-color: #eee; /* Slightly lighter grey canvas background */
  min-height: 0; /* Important to allow flex to shrink canvas */
  display: block; /* Ensure canvas behaves correctly in flex layout */
}

@media (max-width: 768px) {
  .sim-container canvas {
      width: 95%;
      height: 50%;
  }
}