/* GUI.css */
.gui-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /*  IMPORTANT CHANGE: Set to none */
    z-index: 1000; /* Ensure it's on top of the canvas */
    padding: 20px; /* Add some padding around the GUI */
    box-sizing: border-box; /* Include padding in width/height */
  }
  
  .gui-panel {
    background-color: #f0f0f0;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    pointer-events: auto; /* Make panel interactive */
  }
  
  .gui-panel h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .gui-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px; /* Add some spacing between buttons if needed */
    pointer-events: auto; /* Make button interactive */
  }
  
  .gui-button:hover {
    background-color: #45a049;
  }
  
  .gui-slider-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    pointer-events: auto; /* Make slider container interactive */
  }
  
  .gui-slider-container label {
    margin-right: 10px;
  }
  
  .gui-slider {
    flex-grow: 1;
    pointer-events: auto; /* Make slider input interactive */
  }
  
  .gui-slider-value {
    margin-left: 10px;
    font-weight: bold;
  }
  
  .gui-checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    pointer-events: auto; /* Make checkbox container interactive */
  }
  
  .gui-checkbox {
    margin-right: 5px;
    pointer-events: auto; /* Make checkbox input interactive */
  }
  
  .gui-label {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    pointer-events: auto; /* Make label interactive if needed, though usually not */
  }